import React, { Component } from 'react'
import { Grid, Table } from 'semantic-ui-react'
import './orders.scss'

class Orders extends Component {

  state = {
    items: null
  }

  componentDidUpdate (prevProps, prevState) {
    const { db } = this.props;
    if (prevProps.db !== this.props.db) {
      db.collection("userInfo").get().then((snapshot) => {
        var items = [];
        snapshot.docs.forEach(doc => {
          let item = doc.data();
          items.push(item)
        });
        this.setState({
          items: items
        })
      });
    }
  }

  render () {

    const { items, mobile } = this.state

    return (
      <Grid className={'orders-container'}>
        
        <Grid.Column width={16}>

        {
          items &&
          
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  S/N
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Time Registered
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Email
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Phone number
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Full name
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Tickets
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                items.map((item, index) => (
                  <Table.Row key={item.email}>
                    <Table.Cell>
                      {index + 1}
                    </Table.Cell>
                    <Table.Cell>
                      {item.timestamp}
                    </Table.Cell>
                    <Table.Cell>
                      {item.email}
                    </Table.Cell>
                    <Table.Cell>
                      {String(item.number)}
                    </Table.Cell>
                    <Table.Cell>
                      {item.name}
                    </Table.Cell>
                    <Table.Cell>
                      {item.tickets ? item.tickets : "Not paid"}
                    </Table.Cell>
                  </Table.Row>
                ))
              }
            </Table.Body>
          </Table>
        }
        </Grid.Column>

      </Grid>
    )
  }
}

export default Orders
