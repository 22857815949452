import React, {Component} from 'react'
import { Grid, Container, Header, Button, Form } from 'semantic-ui-react'
import { Link, Redirect } from 'react-router-dom'
import './welcome.scss'
import Aux from '../../hoc/Aux'
import firebase from '../../firebase'
import QRCode from 'qrcode'
import Loader from '../Loader/loader'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

class Welcome extends Component {

  constructor (props) {
    super (props)
    this.state = {
      loading: false,
      modalOpen: false,
      newUser: false,
      tickets: "",
      qrCode: null,
      mailchimp: null,
      loadingTicket: false
    }
  }
  
  componentDidMount () {
    const { match, registerRemove, getUserInfo, userInfo, setActive } = this.props
    const { type } = match.params

    userInfo && userInfo.qrCode && this.setState({ qrCode: userInfo.qrCode })

    userInfo && userInfo.tickets && this.setState({ tickets: userInfo.tickets })

    userInfo && userInfo.paymentRef && this.setState({ 
      paymentRef: userInfo.paymentRef 
    })

    setActive('dashboard')
  
    if (type === "newuser") {
      registerRemove()
    } else {
      getUserInfo()
    }
  }

  getReference = (full_name) => {
    let text = full_name;
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";

    for( let i=0; i < 15; i++ )
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  startLoading = () => {
    this.setState({
      loading: true
    })
  }
  
  endLoading = () => {
    this.setState({
      loading: false,
    })
  }

  startTicketLoading = () => {
    this.setState({
      loadingTicket: true
    })
  }
  
  endTicketLoading = () => {
    this.setState({
      loadingTicket: false,
    })
  }

  payNow = () => {
    const { tickets } = this.state
    const { email, name, timestamp, gender } = this.props.userInfo
    var price = tickets && Number(tickets) * 1000
    const ref = this.getReference(name);
    var pay = firebase.functions().httpsCallable('pay');
    var data = {
      email: email,
      name: name,
      timestamp: timestamp,
      gender: gender,
      price: price,
      tickets: tickets,
      ref: ref
    }
    const { userID, db } = this.props

    if (typeof tickets) {
      this.startLoading()

      db.collection("userInfo").doc(userID).set({
        paymentRef: ref
      }, { merge: true }).then(() => {
        pay(data).then(response => {
          this.endLoading()
          window.location.href = response.data.data.link
        }).catch(error => {
          console.log(error)
        })
      });
    }
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }


  updateMailchimp = (ticket) => {
    const { userInfo } = this.props
    var mailchimp = firebase.functions().httpsCallable('mailchimp');
    const data = {
      userInfo: userInfo,
      ticket: ticket
    }

    return mailchimp(data).then(response => {
      this.endTicketLoading()
      this.setState({
        mailchimp: "done"
      })
    }).catch(error => {
      console.log(error)
    })
  }

  generateQR = () => {
    const base = this
    const { userInfo } = this.props
    const { name, timestamp, tickets } = userInfo
    var canvas = document.getElementById('canvas')
    // this.startTicketLoading()
    
    QRCode.toCanvas(canvas, `${name} bought ${tickets} tickets and registered at ${timestamp}`, function (error) {
      if (error) {
        console.error(error)
      } else {
        console.log('success!');
        var dataUrl = canvas.toDataURL();
        // base.updateMailchimp(dataUrl);
        base.setState({
          qrCode: dataUrl
        }, () => {
          base.sendToFirestore()
        })
      }
    })
  }

  sendToFirestore = () => {
    const { qrCode } = this.state
    const { userID, setQr, db } = this.props

    db.collection("userInfo").doc(userID).set({
      qrCode: qrCode
    }, { merge: true }).then(() => {
      console.log("Document successfully written!")
      setQr(qrCode)
    });
  }

  printTicket = () => {
    window.scrollTo(0,0)
    const { userInfo, mobile, smaller } = this.props
    const { name, tickets } = userInfo
    const input = document.getElementById('event-ticket')
    html2canvas(input)
    .then((canvas) => {
      const ticketData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: mobile ? 'portrait' : 'landscape',
        unit: 'in',
        format: mobile && smaller ? [800, 1000] : (mobile && !smaller ? [1000, 1300] : [700, 300])
      });
      mobile ? pdf.addImage(ticketData, 'JPEG', 1, 1) : pdf.addImage(ticketData, 'JPEG', 1, 1);
      // pdf.output('dataurlnewwindow');
      pdf.save(`NiellesCookout-${name}-${tickets}.pdf`);
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const { userInfo, updateProfilePaid, displayName, checkref, checkPayment } = this.props
    const { paymentRef } = this.state

    if (userInfo && userInfo.tickets && (displayName === 'false')) {
      updateProfilePaid()
    }

    if((paymentRef === undefined) && userInfo) {
      userInfo && userInfo.paymentRef && this.setState({ 
        paymentRef: userInfo.paymentRef 
      })
    }

    if (prevState.paymentRef !== paymentRef) {
      if (checkref !== paymentRef) {
        userInfo && paymentRef && (displayName === 'false') && checkPayment(paymentRef)
      }
    }

    if(prevProps.userInfo !== userInfo) {
      userInfo && userInfo.qrCode && this.setState({ qrCode: userInfo.qrCode })
      userInfo && userInfo.tickets && this.setState({ tickets: userInfo.tickets })
    }
  }

  render () {
    const { user, displayName, userInfo, loggedIn, mobile, smaller } = this.props
    const { tickets, qrCode, loading, loadingTicket } = this.state
    var price = tickets && Number(tickets)*1000
    var FinalPrice = price && `N${price}`
    if (loggedIn === false) {
      return <Redirect to="/" />
    }

    return (
      <Aux>
        <Grid className={'welcome-container'}>
          <Grid.Column width={16}>
            <Container className={'welcome-body'} style={{ marginTop: '20%' }}>
              {
                user ?

                <Aux>
                  <Header as="h2" textAlign="left">
                    Welcome{' '}{userInfo && userInfo.name}
                  </Header>

                  <br/>
                  {
                    displayName === "false" ?

                    <Aux>
                      <div className={"pay-section"}>
    
                        <Header as="h3">
                          Step 2 - Buy Ticket <span className={"ticket-cost"}>{FinalPrice}</span>
                          <Header.Subheader><em>Transaction charges apply based on payment method</em></Header.Subheader>
                        </Header>

                        <Form>
                          <Form.Field>
                            <Form.Input type="number" value={tickets} name="tickets" label={"How many tickets will you like to pay for?"} placeholder='Enter desired number' onChange={this.handleChange}/>
                          </Form.Field>
                          <Button className={'primary-main ticket-button'} onClick={this.payNow}>Proceed to pay</Button>
                        </Form>
                        <em><p style={{ marginTop: '7em' }}>*Note: After payment, return to the dashboard to generate your ticket</p></em>
                      </div>
                    </Aux>

                    :

                    <Aux>
                      <Header as="h4">
                        Get ready to have a great time this December at the event. 
                        Bring along a screenshot or print your ticket below for clearance at the venue. 
                        <br/>
                      </Header>
                        {
                          userInfo && userInfo.tickets && userInfo.tickets > 1 &&
                          <p>*Note: You can drop the names of the attendees you paid for at the registration point or collect their wristbands during clearance for entry access</p>
                        }
                      <Container className={"ticket-section"}>
                        <Header as="h3">
                          Ticket
                        </Header>
                        {
                          (userInfo && userInfo.qrCode) ?

                          <Aux>
                            <Grid stackable className={"event-ticket"} id={"event-ticket"} columns={2}>
                              <Grid.Column>
                                <div>
                                  <p>
                                    {userInfo.name}
                                    <br/>
                                    {userInfo.email}
                                    <br/>
                                    This ticket admits {userInfo.tickets} attendee{userInfo.tickets > 1 && "s"}
                                    <br/>
                                    Date: Saturday, 7th December 2019
                                    <br/>
                                    Venue: 49, Abiola Crescent off Toyin street Ikeja
                                    <br/>
                                    Time: 12pm
                                  </p>
                                </div>
                              </Grid.Column>
                              
                              <Grid.Column>
                                <div style={{ textAlign: mobile ? "left" : "right" }}>
                                  <div className="barcode-image">
                                    {/* {
                                      loadingTicket &&

                                      <div className={"loading-ticket animate"}>
                                        <p>Loading...</p>
                                      </div>
                                    } */}

                                    { 
                                      qrCode && 

                                      <img src={qrCode} alt="Ticket QR Code" />

                                    }
                                    
                                  </div>
                                </div>
                              </Grid.Column>

                            </Grid>
                            <Grid>
                              <Grid.Column width={16}>
                                  <div className="print-ticket">
                                    <Button className={"primary-main"} onClick={this.printTicket}>Print Ticket</Button>
                                  </div>
                              </Grid.Column>
                            </Grid>
                          </Aux>

                          :

                          <div>
                            {
                              (userInfo && userInfo.tickets) &&

                              <Aux>
                                <div>
                                  To generate your ticket, click  <Button className={'primary-main generate-ticket'} onClick={this.generateQR}>Generate Ticket</Button>
                                </div>
                                <canvas id="canvas"></canvas>
                              </Aux>
                            }
                          </div>
                        }
                      </Container>
                    </Aux>
                  }
                </Aux>

                :

                <Container>
                  You are not logged in, click <Link to="/login">here</Link> to login
                </Container>

              }

            </Container>

            {
              loading && 

              <Loader loading={loading} message={"Processing payment"} />
            }

          </Grid.Column>
        </Grid>
      </Aux>
    )
  }
}

export default Welcome
