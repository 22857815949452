const config = {
  apiKey: "AIzaSyARp90f0MrifnFjh8YoK5yxpETDHOCKNWQ",
  authDomain: "nielle-19.firebaseapp.com",
  databaseURL: "https://nielle-19.firebaseio.com",
  projectId: "nielle-19",
  storageBucket: "nielle-19.appspot.com",
  messagingSenderId: "454023909832",
  appId: "1:454023909832:web:9716cac9bef685b9d3b0fd",
  measurementId: "G-LMXCHN6874"
}

export default config